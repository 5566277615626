import { useStaticQuery, graphql } from 'gatsby'

const useNoSessionMessage = () => {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(
    graphql`
      query NoTicketQuery {
        allMarkdownRemark(
          filter: { fields: { slug: { eq: "/no-session-message/" } } }
        ) {
          edges {
            node {
              frontmatter {
                title
              }
              html
            }
          }
        }
      }
    `
  )

  if (!edges.length) return ''

  return edges[0].node.html
}

export default useNoSessionMessage
