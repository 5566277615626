import dayjs from 'dayjs'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import { isEmpty } from 'lodash';
import Modal from './modal'
import Dropdown, { DropdownItem } from '../components/dropdown'
import Link from '../components/link'
import useNoSessionMessage from '../hooks/useNoSessionMessage'
import { flatten, groupBy } from '../util/array'
import { classes } from '../util/components'

import styles from './ticket-booking.module.css'

const DATE_FORMAT = 'DD MMM YYYY'
const TIME_FORMAT = 'HH:mm A'
const AMPM_FORMAT = 'h:mm A'

const useLocations = () => {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(
    graphql`
      query LocationData {
        allMarkdownRemark(
          filter: { fields: { collection: { eq: "locations" } } }
          sort: { fields: [frontmatter___title], order: [ASC] }
        ) {
          edges {
            node {
              frontmatter {
                title
              }
              fields {
                venues {
                  frontmatter {
                    title
                    screens {
                      veeziScreenId
                    }
                    accessibility
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const locations = useRef(
    (edges || []).map(e => ({
      ...e.node.frontmatter,
      screens: Array.from(
        new Set(
          flatten(
            (e.node.fields.venues || []).map(v =>
              (v.frontmatter.screens || []).map(s => s.veeziScreenId)
            )
          )
        )
      ),
      venues: (e.node.fields.venues || []).map(e => ({ ...e.frontmatter })),
    }))
  )

  return locations.current
}

const getOrderedDateKeys = dateMapping => {
  return Object.keys(dateMapping).sort((a, b) => new Date(a) - new Date(b))
}


const groupDateSessions = (sessions, veeziFilmId) => {
  const temp = sessions.reduce((map, session) => {
    if (!session || (session.FilmId !== veeziFilmId)) return map
    const d = dayjs(session.PreShowStartTime)
      .startOf('day')
      .toISOString()

    map[d] = [...(map[d] || []), session]
    return map
  }, {})
  Object.keys(temp).forEach(k =>
    temp[k].sort(
      (a, b) => new Date(a.PreShowStartTime) - new Date(b.PreShowStartTime)
    )
  )

  return temp
}

const groupSessionsByDatesAndLocations = (veeziWebSessions, locations, veeziFilmId) => {
  const groupByLocations = groupBy(veeziWebSessions, s => {
    const location = locations.find(l => l.screens.includes(s.ScreenId))

    if (location) return location.title

    // All location will temporarily default to Golden Mile Tower for now
    return 'Golden Mile Tower'
  })

  Object.keys(groupByLocations).forEach(l => {
    const data = groupDateSessions(groupByLocations[l], veeziFilmId)
    if (!isEmpty(data)) {
      groupByLocations[l] = data
    } else {
      delete groupByLocations[l]
    }
  })
  return groupByLocations
}

export const VeeziTicketBooking = ({
  className,
  event,
  veeziWebSessions,
  alwaysShowTitle = false,
  buttonClass,
}) => {
  const handleLocationClick = useRef()
  const handleDateClick = useRef()
  const handleSessionClick = useRef()
  const locations = useLocations()
  const handleBuyTickerClick = useRef()

  const [sessionOptions, setSessionOptions] = useState([])
  const [currentAccessibility, setCurrentAccessibility] = useState([])
  const [newlocationall, setnewlocationall] = useState([])
  const [isVaxxedOpen, setIsVaxxedOpen] = useState(false)
  const dateMobileOptionsEl = useRef()

  const screenAccessibility = useRef(
    locations.reduce((map, l) => {
      l.venues.forEach(v =>
        (v.screens || []).forEach(s => (map[s.veeziScreenId] = v.accessibility))
      )
      return map
    }, {})
  )

  const sessionAccessibilityMap = useCallback(
    s => {
      s.accessibility = screenAccessibility.current[s.ScreenId] || []
      if (event.screenings && event.screenings.length) {
        const screening = event.screenings.find(
          sc => sc.veeziSessionId === s.Id
        )
        if (!screening) return s
        for (const k of Object.keys(screening)) {
          if (screening[k] === true) s.accessibility.push(k)
        }
      }
      return s
    },
    [event.screenings]
  )

  const locationSessions = useRef(
    groupSessionsByDatesAndLocations(
      veeziWebSessions.map(sessionAccessibilityMap),
      locations,
      event.veeziFilmId
    )
  )

  const locationTitles = useRef(Object.keys(locationSessions.current))

  const [selectedLocation, setSelectedLocation] = useState(
    // locationTitles.current.length ? locationTitles.current[0] : 
    null
  )
  const [selectedDate, setSelectedDate] = useState(
    locationTitles.current.length
      ? getOrderedDateKeys(
        locationSessions.current[locationTitles.current[0]]
      )[0]
      : null
  )

  const [selectedSession, setSelectedSession] = useState(
    locationTitles.current.length
      ? locationSessions.current[locationTitles.current[0]][
      getOrderedDateKeys(
        locationSessions.current[locationTitles.current[0]]
      )[0]
      ][0]
      : null
  )
  dateMobileOptionsEl.current = getOrderedDateKeys(
    locationSessions.current[selectedLocation] || {}
  )
  // .filter(d => dayjs(d).isBefore(dayjs().add(1, 'week')))

  const screeningsMap = useMemo(() => {
    return (event.screenings || []).reduce((a, s) => {
      a[s.veeziSessionId] = s

      return a
    }, {})
  }, [event])

  useEffect(() => {
    locationSessions.current = groupSessionsByDatesAndLocations(
      veeziWebSessions.map(sessionAccessibilityMap),
      locations,
      event.veeziFilmId,
    )
    locationTitles.current = Object.keys(locationSessions.current)





    if (locationTitles.current.length) {
      let newarr = []
      locationTitles.current.map((loc, i) => {
        let obj = {}
        obj.location = loc
        obj.data = getOrderedDateKeys(
          locationSessions.current[loc] || {}
        )
        newarr.push(obj)
      })

      setnewlocationall(newarr)
    }






    setSelectedLocation(
      // locationTitles.current.length ? locationTitles.current[0] : 
      null
    )
    setSelectedDate(
      locationTitles.current.length
        ? getOrderedDateKeys(
          locationSessions.current[locationTitles.current[0]]
        )[0]
        : null
    )
    setSelectedSession(
      locationTitles.current.length
        ? locationSessions.current[locationTitles.current[0]][
        getOrderedDateKeys(
          locationSessions.current[locationTitles.current[0]]
        )[0]
        ][0]
        : null
    )
  }, [veeziWebSessions, sessionAccessibilityMap, locations])

  handleLocationClick.current = location => {
    setSelectedLocation(location)
    setSelectedDate(null)
    setSelectedSession(null)
    dateMobileOptionsEl.current = getOrderedDateKeys(
      locationSessions.current[location] || {}
    )
  }


  handleDateClick.current = date => {
    setSelectedDate(date)
    setSelectedSession(null)
  }

  handleSessionClick.current = s => {
    setCurrentAccessibility(screenAccessibility.current[s.ScreenId] || []);
    setSelectedSession(s)
  }

  handleBuyTickerClick.current = () => {
    setIsVaxxedOpen(true)
  }

  if (!locationTitles.current.length) {
    return null
  }

  return (
    <div className={classes(className, styles.ticketsBookingWrapper)}>
      {/* <div className={classes(styles.btnBook, buttonClass)}  >
        BUY TICKETS
      </div> */}
      <div className={styles.ticketsModalMain}>
        <div className={!alwaysShowTitle ? 'md:hidden' : null}>
          <h3 className={styles.ticketsSubtitle}>Book tickets for</h3>
          <h3 className={styles.ticketsTitle}>{event.title}</h3>
        </div>
        {locationTitles.current.length > 0 ? (
          <>
            <div className={styles.ticketField}>
              {/* <h3 className={styles.timeHeading}>Select a location</h3> */}
              <div>
                {/* zp code */}
                {newlocationall.map((item, i) => (
                  <div key={i}>
                    <h3 className={styles.ticketFilmTitle}> {item.location} </h3>

                    {item.data.length > 0 ? (

                      <div className={styles.ticketsTimeList}>
                        {item.data.map((tt, j) => (

                          <div key={j} className={styles.ticketTimeSlot}>

                            {(
                              locationSessions && locationSessions.current && locationSessions.current[item.location] && locationSessions.current[item.location][tt] || []
                            ).map((s, ii) => (

                              s && !s.TicketsSoldOut ? (
                                <button
                                  key={ii}
                                  href={s.Url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={classes(styles.btnBook, buttonClass)}
                                  onClick={() => {
                                    typeof window !== "undefined" && window.gtag && window.gtag("event", `buy-tickets-click`, {
                                      event_category: `Buy Tickets`,
                                      event_label: 'Projector',
                                      name: event.title,
                                    })
                                    typeof window !== "undefined" && window.gtag && window.gtag("event", `click`, {
                                      link_url: s.Url,
                                      link_domain: 'ticketing.oz.veezi.com',
                                      outbound: true
                                    })
                                    if (currentAccessibility.includes('vaxxed')) {
                                      handleBuyTickerClick.current()
                                    } else {
                                      typeof window !== "undefined" && window.open(s.Url, '_blank')
                                    }
                                  }}
                                >
                                  {event.callToAction || (
                                    <>

                                      {s.TicketsSoldOut && (<span className={styles.tagSoldOut}>SOLD OUT</span>)}
                                      {s.FewTicketsLeft && <span className={styles.tagSellingFast}>SELLING FAST</span>}
                                      {(screenAccessibility.current[s.ScreenId] || []).includes(
                                        'vaxxed'
                                      ) ? (
                                        <p className={styles.vaxxed}>Vaxxed Hall</p>
                                      ) : null}
                                      {(screenAccessibility.current[s.ScreenId] || []).includes(
                                        'wheelchair'
                                      ) ? (
                                        <svg
                                          className={styles.accessibilityIcon}
                                          width="13"
                                          height="15"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="#FFF">
                                            <path d="M8.647 13.008c-.15.238-.338.439-.544.64C7.26 14.488 6.06 15 4.746 15a4.783 4.783 0 01-3.358-1.352A4.54 4.54 0 010 10.376c0-1.279.525-2.43 1.388-3.27.169-.165.338-.311.525-.44l.413.97c-.075.054-.131.127-.206.182a3.544 3.544 0 00-1.088 2.558c0 1.006.412 1.901 1.088 2.559a3.767 3.767 0 002.626 1.06c1.031 0 1.95-.402 2.626-1.06.28-.256.506-.567.675-.896l.6.969zM2.059 0c.669 0 1.235.556 1.235 1.25 0 .677-.566 1.25-1.235 1.25A1.249 1.249 0 01.824 1.25C.824.556 1.373 0 2.059 0z" />
                                            <path d="M1.706 4.506a.861.861 0 011.605-.631l.434 1.04h2.908c.378 0 .699.296.699.686a.704.704 0 01-.699.688h-2.36l.34.835H7.37c.32 0 .623.186.774.465l2.284 3.807.7-.316c.433-.204.943 0 1.15.428a.866.866 0 01-.433 1.132l-1.417.613a.86.86 0 01-1.095-.334L6.861 8.833H4.047a.853.853 0 01-.793-.539L1.706 4.506z" />
                                          </g>
                                        </svg>
                                      ) : null}
                                      {screeningsMap[s.Id] &&
                                        screeningsMap[s.Id].closedCaptions ? (
                                        <svg
                                          className={styles.accessibilityIcon}
                                          width="22"
                                          height="15"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none">
                                            <path stroke="#FFF" d="M.5.5h20.429v14H.5z" />
                                            <path
                                              d="M5.357 8.036c0-1.476 1.118-2.679 2.562-2.679.661 0 1.262.257 1.71.674l-.372.44a1.866 1.866 0 00-1.3-.528c-1.125 0-1.961.938-1.961 2.093 0 1.147.843 2.093 1.93 2.093a1.93 1.93 0 001.437-.642l.388.441a2.488 2.488 0 01-1.825.786c-1.451 0-2.569-1.195-2.569-2.678zm5.25 0c0-1.476 1.117-2.679 2.561-2.679.661 0 1.262.257 1.71.674l-.372.44a1.866 1.866 0 00-1.3-.528c-1.125 0-1.961.938-1.961 2.093 0 1.147.844 2.093 1.93 2.093a1.93 1.93 0 001.437-.642l.388.441a2.488 2.488 0 01-1.824.786c-1.452 0-2.57-1.195-2.57-2.678z"
                                              fill="#FFF"
                                            />
                                          </g>
                                        </svg>
                                      ) : null}
                                      <span>{dayjs(tt).format('DD MMM,')} </span>
                                      {/* <span>{dayjs(tt).format('DD MMM, ddd')} </span> */}
                                      <span> {dayjs(s.PreShowStartTime || s.startDate).format(AMPM_FORMAT)}</span>

                                    </>
                                  )}

                                </button>
                              ) : (
                                <button
                                  key={ii}
                                  href={s.Url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={classes(styles.btnBook, buttonClass, styles.groupSelectItemDisabled)}
                                >
                                  {event.callToAction || (
                                    <>

                                      {s.TicketsSoldOut && (<span className={styles.tagSoldOut}>SOLD OUT</span>)}
                                      {s.FewTicketsLeft && <span className={styles.tagSellingFast}>SELLING FAST</span>}
                                      {(screenAccessibility.current[s.ScreenId] || []).includes(
                                        'vaxxed'
                                      ) ? (
                                        <p className={styles.vaxxed}>Vaxxed Hall</p>
                                      ) : null}
                                      {(screenAccessibility.current[s.ScreenId] || []).includes(
                                        'wheelchair'
                                      ) ? (
                                        <svg
                                          className={styles.accessibilityIcon}
                                          width="13"
                                          height="15"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="#FFF">
                                            <path d="M8.647 13.008c-.15.238-.338.439-.544.64C7.26 14.488 6.06 15 4.746 15a4.783 4.783 0 01-3.358-1.352A4.54 4.54 0 010 10.376c0-1.279.525-2.43 1.388-3.27.169-.165.338-.311.525-.44l.413.97c-.075.054-.131.127-.206.182a3.544 3.544 0 00-1.088 2.558c0 1.006.412 1.901 1.088 2.559a3.767 3.767 0 002.626 1.06c1.031 0 1.95-.402 2.626-1.06.28-.256.506-.567.675-.896l.6.969zM2.059 0c.669 0 1.235.556 1.235 1.25 0 .677-.566 1.25-1.235 1.25A1.249 1.249 0 01.824 1.25C.824.556 1.373 0 2.059 0z" />
                                            <path d="M1.706 4.506a.861.861 0 011.605-.631l.434 1.04h2.908c.378 0 .699.296.699.686a.704.704 0 01-.699.688h-2.36l.34.835H7.37c.32 0 .623.186.774.465l2.284 3.807.7-.316c.433-.204.943 0 1.15.428a.866.866 0 01-.433 1.132l-1.417.613a.86.86 0 01-1.095-.334L6.861 8.833H4.047a.853.853 0 01-.793-.539L1.706 4.506z" />
                                          </g>
                                        </svg>
                                      ) : null}
                                      {screeningsMap[s.Id] &&
                                        screeningsMap[s.Id].closedCaptions ? (
                                        <svg
                                          className={styles.accessibilityIcon}
                                          width="22"
                                          height="15"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none">
                                            <path stroke="#FFF" d="M.5.5h20.429v14H.5z" />
                                            <path
                                              d="M5.357 8.036c0-1.476 1.118-2.679 2.562-2.679.661 0 1.262.257 1.71.674l-.372.44a1.866 1.866 0 00-1.3-.528c-1.125 0-1.961.938-1.961 2.093 0 1.147.843 2.093 1.93 2.093a1.93 1.93 0 001.437-.642l.388.441a2.488 2.488 0 01-1.825.786c-1.451 0-2.569-1.195-2.569-2.678zm5.25 0c0-1.476 1.117-2.679 2.561-2.679.661 0 1.262.257 1.71.674l-.372.44a1.866 1.866 0 00-1.3-.528c-1.125 0-1.961.938-1.961 2.093 0 1.147.844 2.093 1.93 2.093a1.93 1.93 0 001.437-.642l.388.441a2.488 2.488 0 01-1.824.786c-1.452 0-2.57-1.195-2.57-2.678z"
                                              fill="#FFF"
                                            />
                                          </g>
                                        </svg>
                                      ) : null}
                                      <span>{dayjs(tt).format('DD MMM,')} </span>
                                      {/* <span>{dayjs(tt).format('DD MMM, ddd')} </span> */}
                                      <span> {dayjs(s.PreShowStartTime || s.startDate).format(AMPM_FORMAT)}</span>

                                    </>
                                  )}

                                </button>
                              )


                            ))}

                          </div>


                        ))}
                      </div>
                    )
                      :
                      'NO SHOWS'
                    }


                  </div>
                ))}
                {/* <Dropdown
                  placeholder="Select a location"
                  onClick={handleLocationClick.current}
                  selected={selectedLocation}
                >
                  {locationTitles.current.map((l, i) => (
                    <DropdownItem value={l} key={i}>
                      {l}
                    </DropdownItem>
                  ))}
                </Dropdown> */}
              </div>
            </div>
            <div />
          </>
        ) : null}
        {/* {(dateMobileOptionsEl.current && dateMobileOptionsEl.current.length > 0) ? (<div className={styles.groupSelect}>
          <h3>Select a date</h3>
          <div>
            {dateMobileOptionsEl.current.map((d, i) => {
              return (
                <div className={`${selectedDate == d ? styles.groupSelectItemActive : ''} ${styles.groupSelectItem}`} key={i} onClick={() => handleDateClick.current(d)}>
                  <span>{dayjs(d).format('DD MMM, ddd')}</span>
                </div>
              )
            })
            }
          </div>
        </div>) : null} */}

        {/* {(locationSessions && locationSessions.current && locationSessions.current[selectedLocation] && locationSessions.current[selectedLocation][selectedDate] && locationSessions.current[selectedLocation][selectedDate].length > 0) ? (<div className={styles.groupSelect}>
          <h3>Select a time</h3>
          <div>
            {(
              locationSessions && locationSessions.current && locationSessions.current[selectedLocation] && locationSessions.current[selectedLocation][selectedDate] || []
            ).map((s, i) => (
              <div className={`${selectedSession && selectedSession.Id == s.Id ? styles.groupSelectItemActive : ''} ${s.TicketsSoldOut ? styles.groupSelectItemDisabled : ''} ${styles.groupSelectItem} ${s.FewTicketsLeft ? styles.itemSellingFast : ''}`} key={i}
                onClick={() => handleSessionClick.current(s)}>
                {s.TicketsSoldOut && (<span className={styles.tagSoldOut}>SOLD OUT</span>)}
                {s.FewTicketsLeft && <span className={styles.tagSellingFast}>Selling Fast</span>}
                <div className={styles.groupSelectItemValue}>
                  {(screenAccessibility.current[s.ScreenId] || []).includes(
                    'vaxxed'
                  ) ? (
                    <p className={styles.vaxxed}>Vaxxed Hall</p>
                  ) : null}
                  {(screenAccessibility.current[s.ScreenId] || []).includes(
                    'wheelchair'
                  ) ? (
                    <svg
                      className={styles.accessibility}
                      width="13"
                      height="15"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="#FFF">
                        <path d="M8.647 13.008c-.15.238-.338.439-.544.64C7.26 14.488 6.06 15 4.746 15a4.783 4.783 0 01-3.358-1.352A4.54 4.54 0 010 10.376c0-1.279.525-2.43 1.388-3.27.169-.165.338-.311.525-.44l.413.97c-.075.054-.131.127-.206.182a3.544 3.544 0 00-1.088 2.558c0 1.006.412 1.901 1.088 2.559a3.767 3.767 0 002.626 1.06c1.031 0 1.95-.402 2.626-1.06.28-.256.506-.567.675-.896l.6.969zM2.059 0c.669 0 1.235.556 1.235 1.25 0 .677-.566 1.25-1.235 1.25A1.249 1.249 0 01.824 1.25C.824.556 1.373 0 2.059 0z" />
                        <path d="M1.706 4.506a.861.861 0 011.605-.631l.434 1.04h2.908c.378 0 .699.296.699.686a.704.704 0 01-.699.688h-2.36l.34.835H7.37c.32 0 .623.186.774.465l2.284 3.807.7-.316c.433-.204.943 0 1.15.428a.866.866 0 01-.433 1.132l-1.417.613a.86.86 0 01-1.095-.334L6.861 8.833H4.047a.853.853 0 01-.793-.539L1.706 4.506z" />
                      </g>
                    </svg>
                  ) : null}
                  {screeningsMap[s.Id] &&
                    screeningsMap[s.Id].closedCaptions ? (
                    <svg
                      className={styles.accessibility}
                      width="22"
                      height="15"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none">
                        <path stroke="#FFF" d="M.5.5h20.429v14H.5z" />
                        <path
                          d="M5.357 8.036c0-1.476 1.118-2.679 2.562-2.679.661 0 1.262.257 1.71.674l-.372.44a1.866 1.866 0 00-1.3-.528c-1.125 0-1.961.938-1.961 2.093 0 1.147.843 2.093 1.93 2.093a1.93 1.93 0 001.437-.642l.388.441a2.488 2.488 0 01-1.825.786c-1.451 0-2.569-1.195-2.569-2.678zm5.25 0c0-1.476 1.117-2.679 2.561-2.679.661 0 1.262.257 1.71.674l-.372.44a1.866 1.866 0 00-1.3-.528c-1.125 0-1.961.938-1.961 2.093 0 1.147.844 2.093 1.93 2.093a1.93 1.93 0 001.437-.642l.388.441a2.488 2.488 0 01-1.824.786c-1.452 0-2.57-1.195-2.57-2.678z"
                          fill="#FFF"
                        />
                      </g>
                    </svg>
                  ) : null}
                  <span>{dayjs(s.PreShowStartTime || s.startDate).format(TIME_FORMAT)}</span>
                </div>
              </div>
            ))}
          </div>
        </div>) : null} */}
        {/* {selectedDate ? (
          <div className={styles.ticketField}>
            <h3 className={styles.timeHeading}>Select a time</h3>
            <div className={styles.times}>
              <Dropdown
                placeholder="Select a time"
                onClick={handleSessionClick.current}
                selected={selectedSession}
              >
                {(
                  locationSessions.current[selectedLocation][selectedDate] || []
                ).map((s, i) => (
                  <DropdownItem value={s} key={i}>
                    <div
                      className={classes(
                        styles.dropdownItem,
                        s.TicketsSoldOut && styles.itemSoldOut,
                        s.FewTicketsLeft && styles.itemSellingFast
                      )}
                    >
                      {(screenAccessibility.current[s.ScreenId] || []).includes(
                        'wheelchair'
                      ) ? (
                        <svg
                          className={styles.accessibility}
                          width="13"
                          height="15"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="#FFF">
                            <path d="M8.647 13.008c-.15.238-.338.439-.544.64C7.26 14.488 6.06 15 4.746 15a4.783 4.783 0 01-3.358-1.352A4.54 4.54 0 010 10.376c0-1.279.525-2.43 1.388-3.27.169-.165.338-.311.525-.44l.413.97c-.075.054-.131.127-.206.182a3.544 3.544 0 00-1.088 2.558c0 1.006.412 1.901 1.088 2.559a3.767 3.767 0 002.626 1.06c1.031 0 1.95-.402 2.626-1.06.28-.256.506-.567.675-.896l.6.969zM2.059 0c.669 0 1.235.556 1.235 1.25 0 .677-.566 1.25-1.235 1.25A1.249 1.249 0 01.824 1.25C.824.556 1.373 0 2.059 0z" />
                            <path d="M1.706 4.506a.861.861 0 011.605-.631l.434 1.04h2.908c.378 0 .699.296.699.686a.704.704 0 01-.699.688h-2.36l.34.835H7.37c.32 0 .623.186.774.465l2.284 3.807.7-.316c.433-.204.943 0 1.15.428a.866.866 0 01-.433 1.132l-1.417.613a.86.86 0 01-1.095-.334L6.861 8.833H4.047a.853.853 0 01-.793-.539L1.706 4.506z" />
                          </g>
                        </svg>
                      ) : null}
                      {screeningsMap[s.Id] &&
                        screeningsMap[s.Id].closedCaptions ? (
                        <svg
                          className={styles.accessibility}
                          width="22"
                          height="15"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none">
                            <path stroke="#FFF" d="M.5.5h20.429v14H.5z" />
                            <path
                              d="M5.357 8.036c0-1.476 1.118-2.679 2.562-2.679.661 0 1.262.257 1.71.674l-.372.44a1.866 1.866 0 00-1.3-.528c-1.125 0-1.961.938-1.961 2.093 0 1.147.843 2.093 1.93 2.093a1.93 1.93 0 001.437-.642l.388.441a2.488 2.488 0 01-1.825.786c-1.451 0-2.569-1.195-2.569-2.678zm5.25 0c0-1.476 1.117-2.679 2.561-2.679.661 0 1.262.257 1.71.674l-.372.44a1.866 1.866 0 00-1.3-.528c-1.125 0-1.961.938-1.961 2.093 0 1.147.844 2.093 1.93 2.093a1.93 1.93 0 001.437-.642l.388.441a2.488 2.488 0 01-1.824.786c-1.452 0-2.57-1.195-2.57-2.678z"
                              fill="#FFF"
                            />
                          </g>
                        </svg>
                      ) : null}

                      {dayjs(s.PreShowStartTime).format(TIME_FORMAT)}
                      {s.TicketsSoldOut ? (
                        <span className={styles.tag}>Sold Out</span>
                      ) : null}
                      {s.FewTicketsLeft ? (
                        <span className={styles.tag}>Selling Fast</span>
                      ) : null}
                    </div>
                  </DropdownItem>
                ))}
              </Dropdown>
            </div>
          </div>
        ) : null} */}
      </div>
      {/* {selectedSession && !selectedSession.TicketsSoldOut ? (
        <button
          href={selectedSession.Url}
          target="_blank"
          rel="noopener noreferrer"
          className={classes(styles.btnBook, buttonClass)}
          onClick={() => {
            typeof window !== "undefined" && window.gtag && window.gtag("event", `buy-tickets-click`, {
              event_category: `Buy Tickets`,
              event_label: 'Projector',
              name: event.title,
            })
            typeof window !== "undefined" && window.gtag && window.gtag("event", `click`, {
              link_url: selectedSession.Url,
              link_domain: 'ticketing.oz.veezi.com',
              outbound: true
            })
            if (currentAccessibility.includes('vaxxed')) {
              handleBuyTickerClick.current()
            } else {
              typeof window !== "undefined" && window.open(selectedSession.Url, '_blank')
            }
          }}
        >
          {event.callToAction || 'Buy Tickets'}
        </button>
      ) : null} */}
      <Modal
        className={styles.vaxxedModal}
        backgroundClassName={styles.vaxxedModalBackground}
        closeClassName={styles.vaxxedModalClose}
        open={isVaxxedOpen}
        handleClose={() => setIsVaxxedOpen(false)}
      >
        <div className={styles.vaxxedModalWrapper}>
          <p>Only patrons who meet any ONE of the following criteria will be allowed to this screening:</p>
          <ul>
            <li>1. Fully vaccinated</li>
            <li>2. Recovered from COVID-19 within the last 180 days after infection with valid memo.</li>
            <li>3. Medically ineligible for all COVID-19 vaccines with valid doctor-certified memo.</li>
            <li>4. Children aged 12 years and below (i.e. born in or after 2010)</li>
          </ul>
          <p>By clicking CONFIRM, you agree that you and all members of your group meet any of the criteria above.</p>
          {selectedSession &&
            <Link className={`${styles.btnModalConfirm} block btn-primary relative`}
              to={selectedSession.Url}
              target="_blank">
              CONFIRM
            </Link>
          }
        </div>
      </Modal>
    </div>
  )
}

export const ExternalTicketBooking = ({ className, event, buttonClass }) => {
  return (
    <div className={className}>
      {event.startDate || event.location ? (
        <div className={styles.ticketsForm}>
          {event.startDate ? (
            <time dateTime={dayjs(event.startDate).toISOString()}>
              <span className={styles.eventDate}>
                {dayjs(event.startDate).format('D MMMM, ddd')}
              </span>
              <span className={styles.eventTime}>
                {dayjs(event.startDate).format('H:mmA')}
              </span>
            </time>
          ) : null}
          {event.location ? (
            <span className={styles.eventLocation}>{event.location}</span>
          ) : null}
        </div>
      ) : null}
      <button
        to={event.bookingURL}
        className={classes(styles.btnBook, buttonClass)}
        onClick={() => {
          typeof window !== "undefined" && window.gtag && window.gtag("event", `buy-tickets-click`, {
            event_category: `Buy Tickets`,
            event_label: 'Projector',
            name: event.title,
          })
          typeof window !== "undefined" && window.open(event.bookingURL, '_blank')
        }}
      >
        {event.callToAction || 'Buy Tickets'}
      </button>
    </div>
  )
}

const TicketTerms = ({ event }) =>
  (event.terms || []).length ? (
    <div className={styles.terms}>
      {event.terms.map((term, i) =>
        term ? (
          <div className={styles.term} key={i}>
            {term}
          </div>
        ) : null
      )}
    </div>
  ) : null

const TicketBooking = ({
  className,
  event,
  veeziWebSessions,
  alwaysShowTitle,
}) => {
const [isloading, setLoading] = useState(false)
useEffect(()=>{
  setLoading(true)
  if(veeziWebSessions.length){
    setLoading(false)
  }

},[veeziWebSessions])

  const noSessionMessage = useNoSessionMessage()
  // show link even if the url is missing, this is to cater
  // for "coming soon" CTA
  const showVodLink = !!(
    (event.platforms || []).includes('vod') &&
    (event.vodURL || event.vodCallToAction)
  )
  const filterItem = veeziWebSessions && veeziWebSessions.filter(item => (item && event && item.FilmId && item.FilmId === event.veeziFilmId))
  const isSessionAvailable = !!(filterItem && filterItem.length)
  const numberOfOptions = [
    showVodLink,
    isSessionAvailable,
    !!event.bookingURL,
  ].filter(o => o).length
  if(isloading) {
    return (
      <div className={styles.noSession}>
          <h2 className="text-2xl">LOADING ...</h2>
      </div>
    )
  } 
  if (!numberOfOptions) {
    return (
      <div className={styles.noSession}>
        {noSessionMessage ? (
          <div dangerouslySetInnerHTML={{ __html: noSessionMessage }} />
          // <h2 className="text-2xl">Loading...</h2>
        ) : (
          <h2 className="text-2xl">No tickets available.</h2>
        )}
      </div>
    )
  }

  let btnIndex = 0

  return (
    <div className={styles[`ticketOptions${numberOfOptions}`]}>
      {event.bookingURL ? (
        <ExternalTicketBooking
          buttonClass={styles[`btnBook${btnIndex++}`]}
          {...{ className, event }}
        />
      ) : null}
      {isSessionAvailable ? (
        <VeeziTicketBooking
          buttonClass={styles[`btnBook${btnIndex++}`]}
          {...{ className, event, veeziWebSessions, alwaysShowTitle }}
        />
      ) : null}
      {showVodLink ? (
        <Link
          to={event.vodURL || ''}
          target="_blank"
          rel="noopener noreferrer"
          className={classes(styles.btnBook, styles[`btnBook${btnIndex++}`])}
        >
          {event.vodCallToAction || 'Watch at Home'}
        </Link>
      ) : null}
      <TicketTerms event={event} />
    </div>
  )
}

export default TicketBooking
