import dayjs from 'dayjs'
import React from 'react'

import Dropdown, { DropdownItem } from '../components/dropdown'
import Link from '../components/link'
import { classes } from '../util/components'
import styles from './ticket-booking.module.css'

const DATE_FORMAT = 'DD MMM YYYY'
const TIME_FORMAT = 'HH:mm'

const VeeziTicketBooking = ({ className, event, alwaysShowTitle }) => {
  return (
    <div className={classes(styles.ticketsBookingWrapper, className)}>
      <div className={styles.ticketsForm}>
        <div className={!alwaysShowTitle ? 'md:hidden' : null}>
          <h3 className={styles.ticketsSubtitle}>Book tickets for</h3>
          <h3 className={styles.ticketsTitle}>{event.title}</h3>
        </div>
        <div className={styles.ticketField}>
          <h3 className={styles.timeHeading}>Select a date</h3>
          <div className={styles.times}>
            <Dropdown placeholder="Select a date">
              <DropdownItem value={new Date()}>
                {dayjs().format(DATE_FORMAT)}
              </DropdownItem>
            </Dropdown>
          </div>
        </div>
        <div className={styles.ticketField}>
          <h3 className={styles.timeHeading}>Select a time</h3>
          <div className={styles.times}>
            <Dropdown placeholder="Select a time">
              <DropdownItem value={new Date()}>
                {dayjs().format(TIME_FORMAT)}
              </DropdownItem>
            </Dropdown>
          </div>
        </div>
      </div>
      <button className={styles.btnBook} onClick={()=> {
        typeof window !== "undefined" && window.gtag && window.gtag("event", `buy-tickets-click`, {
          event_category: `Buy Tickets`,
          event_label: 'Projector',
          name: event.title,
        })
      }}>Buy Tickets</button>
      {(event.terms || []).length ? (
        <div className={styles.terms}>
          {event.terms.map((term, i) =>
            term && term.description ? (
              <div className={styles.term} key={i}>
                {term.description}
              </div>
            ) : null
          )}
        </div>
      ) : null}
    </div>
  )
}

const ExternalTicketBooking = ({ className, event }) => {
  return (
    <div className={className}>
      <div className={styles.ticketsForm}>
        <time dateTime={dayjs(event.startDate).toISOString()}>
          <span className={styles.eventDate}>
            {dayjs(event.startDate).format('D MMMM, ddd')}
          </span>
          <span className={styles.eventTime}>
            {dayjs(event.startDate).format('H:mmA')}
          </span>
        </time>
        <span className={styles.eventLocation}>{event.location}</span>
      </div>
      <button to={event.bookingURL} className={styles.btnBook} onClick={()=> {
          typeof window !== "undefined" && window.gtag && window.gtag("event", `buy-tickets-click`, {
            event_category: `Buy Tickets`,
            event_label: 'Projector',
            name: event.title,
          })
          typeof window !== "undefined" && window.open(event.bookingURL, '_blank')
        }}>
        Buy Tickets
      </button>
    </div>
  )
}

const TicketBookingPreview = ({ className, event, alwaysShowTitle }) => {
  if (event.bookingURL) {
    return ExternalTicketBooking({ className, event, alwaysShowTitle })
  }

  return VeeziTicketBooking({ className, event, alwaysShowTitle })
}

export default TicketBookingPreview
