const MAX_PRIORITY = 10

const SCHEDULES_PRIORITY = {
  'New Release': 2,
  'Coming Soon': 4,
  'Limited Screening': 3,
  'Projector Exclusive': 1,
}

const sortSchedules = (a, b) =>
  (SCHEDULES_PRIORITY[a] || MAX_PRIORITY) -
  (SCHEDULES_PRIORITY[b] || MAX_PRIORITY)

export default function getReleasingSchedules(schedules, isDetails = false) {
  let sortedSchedules = []

  if (schedules && schedules.length) {
    sortedSchedules = schedules.sort(sortSchedules)
  }

  if (isDetails) {
    return sortedSchedules.slice(0, 2)
  }

  return sortedSchedules.length ? sortedSchedules[0] : ''
}
